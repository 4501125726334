
export default {
	name: 'crcProject',
	data() {
		return {
			list: '',
			search: this.$store.state.result,
			searchid: this.$store.state.id,
			total: 0,
			pageSize: 0,
			currentPage: 1
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getcrcProject()
	},
	beforeRouteLeave(to, from, next) {
		from.meta.keepAlive = false;
		next();
	},
	methods: {
		getcrcProject() {
			var _this = this;
			_this.$http.post('/API/PLAT/inPageC', {
				name: _this.search,
				crcUserId: _this.searchid,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getcrcProject()
		},
		searchBtn() {
			var _this = this;
			_this.$http.post('/API/PLAT/inPageC', {
				name: _this.search,
				page: 1
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.list = response.data.data.objArray;
				}
				_this.getcrcProject()
			})
		},
		toPar(id) {
			this.$router.push({ name: 'crcParticulars', params: { id: id } });
			this.$store.commit('getId', id)
		},
		toCrcMena() {
			this.$router.push('/statistics')
		},
		toPro(id) {
			this.$router.push({ name: 'projectParticulars', params: { id: id } })
		},
		//导出
		export2Excel() {
			var _this = this;
			if (_this.list) {
				_this.$http.post('/API/PLAT/inPageCExcel', {
					name: _this.search,
					crcUserId: _this.searchid,
				}, {
					headers: { "token": _this.token }
				}).then(function (response) {
					require.ensure([], () => {
						const {
							export_json_to_excel
						} = require('../../vendor/Export2Excel');
						const tHeader = ['CRC', '科室', '项目简称', '项目名称', '适应症', 'SMO', '启动日期'];
						const filterVal = ['crcName', 'departmentName', 'shortName', 'pName', 'diseaseName', 'enterpriseName', 'startTime'];
						const data = _this.formatJson(filterVal, response.data.data.objArray);
						export_json_to_excel(tHeader, data, 'crc项目详情列表');
					})
				});
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
